

import { CF2Component } from 'javascript/lander/runtime'

export default class CheckboxV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const checkbox = this.element.querySelector('.elCheckboxInput')
    this.preventClickOnLabel = this.element.getAttribute('data-prevent-click-on-label')
    if (this.element.dataset.triggerClickOnWrapper == 'true') {
      this.element.addEventListener('click', (e) => {
        if (e.target.closest('a')) return 
        e.preventDefault();
        this.toggle(checkbox)
      })
    } else if (this.preventClickOnLabel == 'true') {
      this.element.querySelector('.elCheckboxLabel').addEventListener('click', (e) => {
        if (e.target.closest('a')) return 
        e.preventDefault();
      })
      this.element.querySelector('.elCheckbox').addEventListener('click', (e) => {
        this.toggle(checkbox)
      })
    }
    if (this.useCheckboxIcon) {
      const fakeCheckbox = this.element.querySelector('.elCheckbox')
      fakeCheckbox.addEventListener('click', () => {
        this.toggle(checkbox)
      })
    }
  }

  toggle(checkbox) {
    const event = new Event('change');
    checkbox.checked = !checkbox.checked;
    checkbox.dispatchEvent(event);
  }



}

window["CheckboxV1"] = CheckboxV1

